import { TextInputWithLabel } from '@/src/common/components/elements/Forms/TextInputWithLabel'
import { BaseSyntheticEvent } from 'react'
import { clearFormErrorForField } from '@/src/common/utilities/clearFormErrorForField'
import { emailMax } from '@/src/modules/build-a-card/constants'
import { inputHasError } from '@/src/common/utilities/inputHasError'
import { FormErrorDisplay } from '@/src/modules/build-a-card/FormErrorDisplay'
import { ValidationError } from 'yup'
import { THandleListUpdate } from '@/src/modules/build-a-card/inputs/types'
import { handleRecipientChange } from '@/src/modules/build-a-card/utilities/handleRecipientChange'

type TEmailProps = {
    email: string
    setEmail: Function
    formErrors: ValidationError[]
    setFormErrors: Function
    fieldId: string
    placeholder?: string
    label?: string
    onBlur?: () => void
    handleList?: THandleListUpdate
    isRequired?: boolean
}

const EmailInput = ({
    email,
    setEmail,
    formErrors,
    setFormErrors,
    fieldId,
    handleList,
    placeholder,
    onBlur,
    label = 'Email address',
    isRequired = false,
}: TEmailProps) => {
    const fieldLabel: string = email?.length || isRequired ? label : ''

    return (
        <div key={fieldId} style={{ width: '100%' }}>
            <TextInputWithLabel
                isRequired={isRequired}
                id={fieldId}
                type={'email'}
                value={email}
                placeholder={placeholder ?? label}
                autoComplete={'email'}
                label={fieldLabel}
                onBlur={onBlur}
                onChange={(e: BaseSyntheticEvent) => {
                    if (handleList) {
                        handleRecipientChange({ ...handleList, e: e })
                    } else {
                        let trimmedValue = e.currentTarget.value.trim()
                        setEmail(trimmedValue)
                    }

                    clearFormErrorForField({
                        path: fieldId,
                        formErrors: formErrors,
                        setFormErrors: setFormErrors,
                    })
                }}
                maxLength={emailMax}
                hasError={inputHasError(formErrors, fieldId)}
                data-testid={'email-address'}
            />
            <FormErrorDisplay formErrors={formErrors} path={fieldId} />
        </div>
    )
}

export default EmailInput
