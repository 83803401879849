import { GradientText } from '@/src/common/components/marketing/heros/HomepageHeroGreetingCards'
import { BlueLink } from '@/src/modules/login/StyledComponents'
import AlertWithIcon from '@/src/common/components/elements/Alerts/AlertWithIcon'
import { ClipLoader } from 'react-spinners'

type TVerificationProps = {
    email: string
    resendVerificationEmail: any
    loading: boolean
    resentVerificationMessage: {
        message: string
        type: 'success' | 'error'
    } | null
}

const EmailVerificationScreen = ({
    email,
    resendVerificationEmail,
    resentVerificationMessage,
    loading,
}: TVerificationProps) => {
    return (
        <div>
            <h2 style={{ fontSize: '2.1rem', wordWrap: 'break-word' }}>
                We sent a verification email to
                <br />
                <GradientText>{email}</GradientText>
            </h2>
            <p>
                Please click on the verification link that we sent to your email
                address and return to this window.
            </p>

            <BlueLink onClick={loading ? () => {} : resendVerificationEmail}>
                {loading ? (
                    <ClipLoader size={30} />
                ) : (
                    "Didn't receive your activation code?"
                )}
            </BlueLink>
            {resentVerificationMessage && !loading && (
                <AlertWithIcon
                    iconName={resentVerificationMessage.type}
                    size={'sm'}
                    style={{ marginTop: 15 }}
                >
                    <p>{resentVerificationMessage.message}</p>
                </AlertWithIcon>
            )}
            <p style={{ fontSize: 14, marginTop: 30, marginBottom: 0 }}>
                PerfectGift wants your gift experience to be as safe and secure
                as possible. To facilitate that, we require an account to be
                signed into or created to ensure you are the only person
                accessing your gift and gift details. Please see our{' '}
                <a
                    href={'https://www.perfectgift.com/terms-of-use'}
                    target="_open"
                    style={{ color: 'var(--colors-primary' }}
                >
                    Terms of Service
                </a>{' '}
                and{' '}
                <a
                    href={'https://www.perfectgift.com/privacy-policy'}
                    target="_open"
                    style={{ color: 'var(--colors-primary' }}
                >
                    Privacy Policy
                </a>{' '}
                for more details about our policies.
            </p>
        </div>
    )
}

export default EmailVerificationScreen
